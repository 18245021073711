<template>
  <div class="news-list">
    <sub-banner :title="banner.title" :subTitle="banner.subTitle" :bannerBg="banner.bannerBg"></sub-banner>
    <div class="list-wrap">
      <template>
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="finishedText"
          @load="fentchNewsMore"
        >
          <ol class="list">
            <li v-for="item of newsList" :key="item.contentItemId" class="van-hairline--bottom">
              <router-link class="link" :to="'/company/news/detail?id='+item.contentItemId">
                <h4 class="date">{{item.createdAt}}</h4>
                <h3 class="title">{{item.title}}</h3>
                <p class="describe">{{item.decrible}}</p>
              </router-link>
            </li>
          </ol>
        </van-list>
        <van-empty v-if="newsList.length === 0" description="暂无数据" />
      </template>
    </div>
  </div>
</template>

<script>
import pageMixin from '@/mixins/pageMixin'
import subBanner from '@/components/subBanner'
export default {
  name: 'newsList',
  mixins: [pageMixin],
  components: {
    subBanner
  },
  data () {
    return {
      banner: {
        title: '新闻资讯',
        subTitle: 'News and information',
        bannerBg: require('@/assets/images/news/banner.jpg')
      },
      loading: false,
      finished: false,
      finishedText: '已全部加载',
      page: {
        pageIndex: 0,
        pageSize: 5
      },
      total: 1,
      newsList: []
    }
  },
  methods: {
    fetchNews () {
      this.loading = true
      this.api.fetchNewsContent(this.page).then(data => {
        if (!data.newsItems || data.newsItems.length === 0) {
          this.finished = true
          this.finishedText = ''
        }
        if (this.newsList.length === 0) {
          this.newsList = data.newsItems
        } else {
          this.newsList = [...this.newsList, ...data.newsItems]
        }
        this.page.pageIndex = data.currentPage
        this.page.pageSize = data.pageSize
        this.total = data.totalPage
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.loading = false
      })
    },
    handleChange (value) {
      this.page.pageIndex = value
      this.fetchNews()
    },
    fentchNewsMore () {
      if (this.page.pageIndex === this.total) {
        this.finished = true
        return
      }
      this.page.pageIndex++
      this.fetchNews()
    }
  },
  created () {
    // this.fetchNews()
  }
}
</script>

<style lang="less" scoped>
  .news-list{
    /deep/ .list-wrap{
      min-height: 50vh;
      .van-list__finished-text{
        padding: 25px 0;
        font-size: 22px;
      }
      .van-empty__description{
        font-size: 22px;
      }
    }
    .list{
      li{
        padding: 40px;
        color: @grey;
        .date{
          font-size: 28px;
        }
        .title{
          margin: 30px 0 14px;
          color: @black;
          font-size: 28px;
          font-weight: 600;
          line-height: 36px;
        }
        .describe{
          font-size: 24px;
          line-height: 40px;
          max-height: 160px;
          .textEllipsisMulti(4);
        }
      }
    }
  }
</style>
